import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Notifications from './notifications';

import './style.scss';

class Layout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                                mainMenu {
                                    uri
                                    title
                                }
                                footer {
                                    uri
                                    title
                                }
                            }
                        }
                    }
                `}
                render={(data) => {
                    return (
                        <main id='content' className='wrapper'>
                            <div className='layout scbz-content-area'>
                                <div className='main'>
                                    <div className='page-content'>
                                        <Notifications notification={this.props.notification} />
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </main>
                    );
                }}
            />
        );
    }
}

export default Layout;

import React, { Component } from 'react';

import './notifications.scss';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.notification.notification,
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.notification.notification !==
            this.props.notification.notification
        ) {
            this.setState({
                message: this.props.notification.notification,
            });
        }
    }

    render() {
        if (this.state.message) {
            const alertType = 'success';

            return (
                <div
                    className={alertType + ' text-center notification'}
                    role="alert"
                >
                    {this.state.message}
                </div>
            );
        } else {
            return <></>;
        }
    }
}

export default Notifications;

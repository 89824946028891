import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../components/layouts/Layout';
import SEO from '../components/seo';

import '../components/home/style.scss';

export const Home = ({ user, notification, config, actions }) => {
    return (
        <Layout
            user={user}
            config={config}
            notification={notification}
            actions={actions}
        >
            <SEO title="Home" />
            <HomeInner />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

export const HomeInner = () => {
    return (
        <div className="home primary-page-content">
            <div className="desktop-view hidden-xs"></div>
            <div className="mobile-view visible-xs"></div>
        </div>
    );
};
